<template>
  <div>
    <title-bar
      submitBtn
      :submitBtnTitle="$t('parameter.create')"
      :title="$t('parameter.new')"
      @submitPressed="submitPressed"
    />
    <parameter-form :parameter-object="parameter" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ParameterForm from './components/ParameterForm.vue';

export default {
  components: {
    TitleBar,
    ParameterForm
  },
  data() {
    return {
      action: null,
      parameter: {
        name: '',
        url: '',
        items: [],
        description: '',
        fileURL: null,
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
};
</script>

